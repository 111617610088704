<template>
    <div>
        <Card class="mt-5 no-border no-padding">
            <template #title>
                Procedimentos em dia
                <TourComponent tourName="ExamesGuiaGestaoMedica"/>
            </template>
            <template #content>
                <DataTable :value="funcionariosComExamesEmDia" dataKey="id">
                    <Column style="width: 10%">
                        <template #body="slotProps">
                            <Button
                                class="p-button-outlined p-button-info p-button-sm Examesstep1"
                                @click="abrirModalDeExames(slotProps?.data, $event)"
                            >
                                <i class="pi pi-arrow-down mr-1" />
                                <span>Ver exames</span>
                            </Button>
                        </template>
                    </Column>
                    <Column class="Examesstep2" field="name" header="Funcionário" style="width: 60%"></Column>
                    <Column style="width: 10%">
                        <template #body="slotProps">
                            <button class="p-link p-button-sm p-button-text Examesstep3" @click="openDialog(slotProps.data)">
                                <i class="pi pi-download mr-1" />
                                <span>Baixar o ASO</span>
                            </button>
                        </template>
                    </Column>
                </DataTable>

                <div v-if="showMessage" class="mt-6">
                    <Message>Em caso de aceite de um exame em dia está sujeito a <b>cobrança</b>.</Message>
                </div>
            </template>
        </Card>

        <OverlayExamesFuncionario ref="modalDeExames" v-model:funcionario="funcionario" />

        <AppAnexoDialog
            v-if="showAnexoDialog"
            v-model:visible="showAnexoDialog"
            :enableImport="false"
            :enableRemove="false"
            title="ASOs para o Funcionário"
            tipo="ASO"
            origem="FUNCIONARIO_ASO"
            v-model:origemId="idFuncionario"
            @onClickCloseAnexo="showAnexoDialog = false"
        />
    </div>
</template>

<script>
import AppAnexoDialog from '../../../../components/AppAnexoDialog.vue';
import OverlayExamesFuncionario from './OverlayExamesFuncionario.vue';
import TourComponent from '../../../../components/TourComponent';

export default {
    components: {
        OverlayExamesFuncionario,
        AppAnexoDialog,
        TourComponent
    },
    props: {
        funcionarios: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            funcionario: {},
            funcionariosComExamesEmDia: [],
            idFuncionario: 0,
            showAnexoDialog: false,
            showMessage: false,
            submitted: false
        };
    },
    watch: {
        funcionario: {
            handler(valor) {
                const funcionarios = this.funcionarios.filter((funcionario) => {
                    return funcionario.id !== valor.id;
                });

                this.$emit('update:funcionarios', [...funcionarios, valor]);
            },
            deep: true
        },
        funcionarios: {
            handler(valor) {
                this.funcionariosComExamesEmDia = valor.filter((funcionario) => {
                    return funcionario.exames?.some((exame) => exame.dataRefazerExame);
                });
            },
            deep: true
        }
    },
    mounted() {
        this.funcionariosComExamesEmDia = this.funcionarios.filter((funcionario) => {
            return funcionario.exames.some((exame) => exame.dataRefazerExame);
        });
    },
    methods: {
        onAceitarExameEmDia() {
            this.showMessage = true;
        },
        openDialog(data) {
            this.idFuncionario = data.id;
            this.showAnexoDialog = true;
        },
        abrirModalDeExames(funcionario, event) {
            this.funcionario = funcionario;
            this.$refs.modalDeExames.$refs.modalDeExames.show(event);
        }
    }
};
</script>

<style lang="scss" scoped>
.p-card.no-border {
    box-shadow: unset;
}

.p-card.no-padding > :deep(.p-card-body) {
    padding: 0;
}
</style>
