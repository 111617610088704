<template>
    <OverlayPanel ref="modalDeExames" appendTo="body">
        <DataTable :value="funcionario?.exames" dataKey="procedimento" style="width: 55rem">
            <Column field="procedimento.name" header="Procedimento" style="width: 30%">
                <template #body="slotProps">
                    <i v-if="!slotProps.data.aceitar" class="pi pi-exclamation-triangle mr-2" style="color: var(--yellow-500)"> </i>
                    <span>{{ slotProps.data.procedimento.name }}</span>
                </template>
            </Column>
            <Column field="dataUltimaRealizacao" header="Realizado em" style="width: 15%">
                <template #body="slotProps">
                    {{ formatarData(slotProps.data.dataUltimaRealizacao) }}
                </template>
            </Column>
            <Column field="dataRefazerExame" header="Refazer em" style="width: 10%">
                <template #body="slotProps">
                    {{ formatarData(slotProps.data.dataRefazerExame) }}
                </template>
            </Column>
            <Column field="periodicidade" header="Periodicidade" style="width: 10%">
                <template #body="slotProps"> {{ slotProps.data.periodicidade }} Meses </template>
            </Column>
            <Column style="width: 25%">
                <template #body="slotProps">
                    <div class="flex flex-row w-full">
                        <div class="flex flex-wrap gap-3">
                            <div class="flex align-items-center">
                                <RadioButton
                                    :disabled="slotProps.data.procedimento.clinical"
                                    v-model="slotProps.data.aceitar"
                                    inputId="aceitar"
                                    :value="true"
                                    @change="aceitarProcedimento(slotProps.data.procedimento)"
                                />
                                <label for="aceitar" class="ml-2">Aceitar</label>
                            </div>
                            <div class="flex align-items-center">
                                <RadioButton
                                    :disabled="slotProps.data.procedimento.clinical"
                                    v-model="slotProps.data.aceitar"
                                    inputId="rejeitar"
                                    :value="false"
                                    @change="rejeitarProcedimento(slotProps.data.procedimento)"
                                />
                                <label for="rejeitar" class="ml-2">Rejeitar</label>
                            </div>
                        </div>
                    </div>
                </template>
            </Column>
        </DataTable>
        <div class="grid grid-nogutter justify-content-end pt-2">
            <Button class="w-2 p-2 Examesstep5" label="Fechar" @click="fecharModalDeExames()" />
        </div>
    </OverlayPanel>
</template>

<script>
import * as moment from 'moment';

export default {
    props: {
        funcionario: {
            type: Object,
            required: true
        }
    },
    methods: {
        fecharModalDeExames(event) {
            this.$refs.modalDeExames.hide(event);
        },
        formatarData(data) {
            if (data) return moment(data).format('DD/MM/YYYY');
            return '';
        },
        aceitarProcedimento(procedimento) {
            const procedimentos = [...this.funcionario.procedimentos, procedimento];
            this.$emit('update:funcionario', { ...this.funcionario, procedimentos });
        },
        rejeitarProcedimento(procedimento) {
            const procedimentos = this.funcionario.procedimentos.filter((item) => {
                return item.id !== procedimento.id;
            });
            this.$emit('update:funcionario', { ...this.funcionario, procedimentos });
        }
    }
};
</script>